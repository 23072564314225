function toggleDrawer(event) {
  var el = document.querySelector(".c-drawer");
  if (el) el.classList.toggle("is-open");

  if (el.classList.contains("is-open")) {
    document.body.classList.add("is-open");
  } else {
    document.body.classList.remove("is-open");
  }
}

window.onYouTubeIframeAPIReady;

function topCover() {
  if (!document.getElementById("ytPlayer")) return;

  const tag = document.createElement("script");

  tag.src = "https://www.youtube.com/iframe_api";
  const firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  var player, ytPlayer;
  onYouTubeIframeAPIReady = function () {
    player = new YT.Player("ytPlayer", {
      height: "360",
      width: "640",
      videoId: window.innerWidth > 768 ? "5ItJE5S_o_U" : "GAbi7MmnAic",
      events: {
        "onReady": onPlayerReady,
        "onStateChange": onPlayerStateChange,
      },
    });

    ytPlayer = new YT.Player('player', {
      videoId: this.videoId,
      playerVars: {
        // 'autoplay': 1,
        'controls': 0,
        'loop':1,
        'playlist': 'qxLr37OM6u4'
      }
    });
  }

  function onPlayerReady(event) {
    event.target.mute();
    event.target.playVideo();

    const el = document.getElementById("ytPlayerWrap");
    if (el) el.classList.add("is-ready");
  }

  function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.ENDED) {
      player.seekTo(0, true);
    }
  }

  var isSp = false;
  function onResizePlayer() {
    if (window.innerWidth <= 768 && !isSp) {
      player.stopVideo();
      player.clearVideo();
      player.loadVideoById({
        videoId: "GAbi7MmnAic",
      });
      isSp = true;
    } else if (window.innerWidth > 768 && isSp) {
      player.stopVideo();
      player.clearVideo();
      player.loadVideoById({
        videoId: "5ItJE5S_o_U",
      });
      isSp = false;
    }
  }

  window.addEventListener("resize", onResizePlayer, false);

  function modalOnOpen(target) {
    target.addEventListener('click', function (event) {
      document.querySelector('#modal-video').classList.add('open');
      document.querySelector('#modal-video').classList.remove('close');

      ytPlayer.playVideo();
      player.stopVideo();
    });
  }

  document.querySelector('.js-modal-video-close').addEventListener('click', event => {
    document.querySelector('#modal-video').classList.add('close');
    document.querySelector('#modal-video').classList.remove('open');

    ytPlayer.stopVideo();
    player.playVideo();
  });

  document.querySelectorAll('.js-modal-video-open').forEach((element, index) => {
    modalOnOpen(element);
  });
}

function toggleTabContent () {
  const tabs = document.querySelectorAll(".description-Tabs-Nav a");
  const contents = document.querySelectorAll(".description-Tabs-Content");

  const className = "cr";

  tabs.forEach(tab => {
    tab.addEventListener("click", (event) => {
      event.preventDefault();
      const url = new URL(window.location.href);
      const hash = tab.getAttribute("href");

      const target = document.getElementById(`${hash.replace('#', '')}`);
      
      if (target) {
        tabs.forEach(tab => tab.parentElement.classList.remove(className));
        contents.forEach(content => content.classList.remove(className));

        tab.parentElement.classList.add(className);
        target.classList.add(className);

        window.history.pushState({}, document.title, `${url.origin}${url.pathname}${hash}`);
      }
    }, false);

    if (window.location.hash == tab.getAttribute("href")) {
      const event = new Event("click");
      tab.dispatchEvent(event);
    }
  });
}

function recruitAnchorLink() {
  const items = document.querySelectorAll(".message-Item-Link a");
  const tabs = document.querySelectorAll(".description-Tabs-Nav a");

  items.forEach(item => {
    item.addEventListener("click", event => {
      event.preventDefault();

      const url = new URL(window.location.href);
      const hash = item.getAttribute("href");
      const target = document.getElementById(`${hash.replace('#', '')}`);

      if (target) {
        window.history.pushState({}, document.title, `${url.origin}${url.pathname}${hash}`);

        for (let i = 0; tabs.length > i; i++) {
          if (tabs[i].getAttribute("href") == hash) {
            const event = new Event("click");
            tabs[i].dispatchEvent(event);
          } 
          continue;
        }

        $('body,html').animate({ scrollTop: $('.description-Tabs-Contents').offset().top }, 400, 'swing');
      }
    });
  });
}

function main() {
  const toggler = document.querySelector(".c-drawer__toggler");
  if (toggler) toggler.addEventListener("click", toggleDrawer, {});

  topCover();
  toggleTabContent();
  recruitAnchorLink();
}

window.addEventListener("DOMContentLoaded", main, {});